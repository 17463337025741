import location_icon from "../icons/location_icon.svg";
import { LocationDetails } from "../interfaces/LocationDetails";
import { useTranslation } from "react-i18next";
import { copyText } from "../services/Utilities";
import { useGoogleAnalyticsContext } from "../services/GoogleAnalyticsContext";

const LocationAddress = ({ data }: { data: LocationDetails | undefined }) => {
  const { t } = useTranslation();
  const googlePushEvent = useGoogleAnalyticsContext();
  const IfValueExists = (val: string | null | undefined, comma: boolean) => {
    if (val && val !== "NULL" && val !== "null") {
      if (comma) {
        return `, ${val}`;
      } else return val;
    } else {
      return "";
    }
  };
  const clickHandler = () => {
    googlePushEvent("click_copy_address_map");
    copyText(addressText.toUpperCase());
  };
  const addressText = `${IfValueExists(
    data?.address?.street,
    false
  )} ${IfValueExists(data?.address?.number, false)}
     ${IfValueExists(data?.address?.city, true)}  ${IfValueExists(
    data?.address?.postalCode,
    true
  )} ${IfValueExists(data?.address?.country, true)}`
    .trim()
    .replace("_", " ") // replaces underscore with space
    .replace(/\s*,/g, ",") // removes whitespaces before commas
    .replace(/^,+/, ""); // removes first character is its comma
  return (
    <div className={"click-to-copy"} onClick={clickHandler}>
      <div className={"click-to-copy-icon-text"}>
        <img src={location_icon} alt={"location icon"}/>
        <p>{t("clickToCopy")}</p>
      </div>
      <p
        className={'gtm-track'}
        style={{ textTransform: "uppercase" }}
      >
        {addressText}
      </p>
    </div>
  );
};
export default LocationAddress;
