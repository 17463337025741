import { StoreInfo, StoreInfoResponse } from "../interfaces/StoreInfo";
import { ApiClient } from "./Utilities";

export const ExcludedGooglePlacesTypes = ["church"];
export class PosDetailsService {
  public static async fetchPosDetailsByPlaceId(
    placeId: string | null | undefined
  ): Promise<StoreInfo | null> {
    try {
      const {
        data: { "store-info": storeInfo }
      } = await ApiClient.get<StoreInfoResponse>(
        `fetch/store-info?storeId=${placeId}`
      );

      return storeInfo;
    } catch (error) {
      console.error("[POS_DETAILS_SERVICE] error: ", error);
      return null;
    }
  }
}
