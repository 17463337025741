import "./LocationDetails.css";
import { memo, useState } from "react";
import close_button from "../icons/close_button.svg";
import { getSchedule } from "../services/LocationDetails";
import Schedule from "./Schedule";
import { LocationDetails as LocationDetailsInterface } from "../interfaces/LocationDetails";
import OpenNow from "./OpenNow";
import LocationAddress from "./LocationAddress";
import { useTranslation } from "react-i18next";
import GetDirections from "./GetDirections";
import { isMobile } from "../services/Utilities";

interface LocationDetailsProps {
  apiKey: string | undefined;
  locationData?: LocationDetailsInterface;
  showLocationDetails: boolean;
  onClose: () => void;
  showFullImage: boolean;
  setShowFullImage: (x: boolean) => void;
}

const LocationDetails = ({
  apiKey,
  locationData,
  showLocationDetails,
  onClose,
  showFullImage,
  setShowFullImage,
}: LocationDetailsProps) => {
  const { t } = useTranslation();
  const [showSchedule, setShowSchedule] = useState(true);
  const locationSchedule = locationData?.openingHours?.weekdayDescriptions_;
  const storeSchedule = getSchedule(locationSchedule);
  const imageName = locationData?.photos?.[0]?.name_;
  const storeImage = `https://places.googleapis.com/v1/${imageName}/media?key=${apiKey}&maxWidthPx=2048`;
  const _isMobile = isMobile();
  if (!showLocationDetails) return null;

  const styles: {
    image: { [key: string]: React.CSSProperties };
  } = {
    image: {
      hidden: {
        visibility: "hidden", // Explicitly using a valid value
        minHeight: "auto",
        marginTop: "50px",
      },
      fullImage: {
        backgroundImage: `url(${storeImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "absolute",
        left: 0,
        right: 0,
        zIndex: 4,
        transform: "translateY(-50%)",
        top: "50%",
        height: "30%",
      },
      defaultImage: {
        backgroundImage: `url(${storeImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      },
    },
  };

  const heightCheck = _isMobile
    ? !locationData?.address?.street && !imageName && !storeSchedule
      ? { height: "30%" }
      : locationData?.address?.street &&
          ((!imageName && storeSchedule) || (imageName && !storeSchedule))
        ? { height: "80%" }
        : locationData?.address?.street && !imageName && !storeSchedule
          ? { height: "55%" }
          : { height: "100%" }
    : { height: "100%" };

  const MemoImg = memo(() =>
    !imageName ? (
      <img
        className={"storeImage"}
        src={storeImage}
        alt={"alt-text"}
        style={{ display: `${showFullImage ? "block" : "none"}` }}
      />
    ) : null,
  );

  return (
    <div
      className={"location-container"}
      style={{ ...heightCheck, left: "0px" }}
    >
      <div className={"location-inner-container"}>
        <div>
          <button
            className={"close-button-container"}
            onClick={onClose}
            style={{ border: "none", background: "transparent" }}
          >
            <img
              className={"close-button"}
              src={close_button}
              alt={"close button"}
            />
          </button>
          <div
            className={"storeImage-container"}
            onClick={() => setShowFullImage(!showFullImage)}
            style={
              !imageName
                ? styles.image.hidden
                : showFullImage
                  ? styles.image.fullImage
                  : styles.image.defaultImage
            }
          >
            <MemoImg />
            <div className={"tap-to-view-container"}>
              <p className={"tap-to-view"}>{t("tapToView")}</p>
            </div>
          </div>
          <h2 className={"location-title"}>{locationData?.name}</h2>
          {locationData?.address?.street && (
            <LocationAddress data={locationData} />
          )}
          {storeSchedule !== undefined && (
            <OpenNow
              showSchedule={showSchedule}
              toggleSchedule={() => setShowSchedule(!showSchedule)}
              data={locationData}
            />
          )}
          {locationSchedule && locationSchedule.length > 0 && (
            <Schedule showSchedule={showSchedule} schedule={storeSchedule} />
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            height: "100%",
            // marginBottom: '10px'
          }}
        >
          <GetDirections
            directionsTo={{ lat: locationData?.lat, lng: locationData?.lng }}
          />
        </div>
      </div>
      <div
        className={"overflow"}
        style={{ display: `${showFullImage ? "block" : "none"}`, width: `` }}
        onClick={() => setShowFullImage(false)}
      ></div>
    </div>
  );
};
export default LocationDetails;
