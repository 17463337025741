import { ProductDetails, UrlProduct } from "./interfaces/Product";
import BetanoStorePin from "./icons/BetanoStorePin.svg";
import OktoStorePin from "./icons/OktoStorePin.svg";
import StoiximanStorePin from "./icons/StoiximanStorePin.svg";
import NovicashStorePin from "./icons/NovicashStorePin.svg";
import BetssonStorePin from "./icons/BetssonStorePin.svg";

export const LOCATION_CONSENT_COOKIE = "LocationConsent";

export const COOKIES_CONSENT_COOKIE = "CookiesConsent";

export const MAX_MOBILE_SCREEN_WIDTH_PIXELS = 500;

export const DISABLE_LOCATION_CONSENT = true;

export const DEFAULT_ZOOM = 10;

export const SHOW_QUERY_DEBUG = process.env.REACT_APP_QUERY_DEBUG === "true";

const { protocol, host, pathname, search } = window.location;

const externalStoreLocatorPath = host.startsWith("okto.cash")
  ? ""
  : "/external-store-locator";
const apiHost =
  host.startsWith("dev") || host.startsWith("sit")
    ? host
    : `${host}${externalStoreLocatorPath}`;

export const BACK_END_BASE_URL = process.env.REACT_APP_BACK_END_BASE_URL
  ? process.env.REACT_APP_BACK_END_BASE_URL
  : `${protocol}//${apiHost}`;

const isUrlProduct = (argument: unknown): argument is UrlProduct =>
  !!argument &&
  typeof argument === "string" &&
  (argument === "cash" ||
    argument === "cashout" ||
    argument === "betano" ||
    argument === "betanocashout" ||
    argument === "stoiximan" ||
    argument === "stoiximancashout" ||
    argument === "oktowallet" ||
    argument === "oktovoucher" ||
    argument === "oktopin" ||
    argument === "novi" ||
    argument === "novicashout" ||
    argument === "betsson" ||
    argument === "betssoncashout");
// TODO: remove as...
const pathProduct = pathname.split("/")[2];
const PRODUCT: UrlProduct =
  (((isUrlProduct(pathProduct) && pathProduct) ||
    new URLSearchParams(search).get("product")) as UrlProduct) ?? "cash";

export const getProductDetails = (
  product: string = PRODUCT
): ProductDetails => {
  switch (product.toLowerCase()) {
    case "cashout":
      return {
        pinSVG: OktoStorePin,
        name: PRODUCT,
        description: "OKTO.CASH-out"
      };
    case "stoiximan":
      return {
        pinSVG: StoiximanStorePin,
        name: PRODUCT,
        description: "Stoiximan CASH"
      };
    case "stoiximancashout":
      return {
        pinSVG: StoiximanStorePin,
        name: PRODUCT,
        description: "Stoiximan CASH-out"
      };
    case "betano":
      return {
        pinSVG: BetanoStorePin,
        name: PRODUCT,
        description: "Betano CASH"
      };
    case "betanocashout":
      return {
        pinSVG: BetanoStorePin,
        name: PRODUCT,
        description: "Betano CASH-out"
      };
    case "oktowallet":
      return {
        pinSVG: OktoStorePin,
        name: PRODUCT,
        description: "OKTO.WALLET"
      };
    case "oktovoucher":
      return {
        pinSVG: OktoStorePin,
        name: PRODUCT,
        description: "OKTO.VOUCHER"
      };
    case "oktopin":
      return { pinSVG: OktoStorePin, name: PRODUCT, description: "OKTO.PIN" };
    case "novi":
      return {
        pinSVG: NovicashStorePin,
        name: PRODUCT,
        description: "Novi CASH"
      };
    case "novicashout":
      return {
        pinSVG: NovicashStorePin,
        name: PRODUCT,
        description: "Novi CASH-out"
      };
    case "betsson":
      return {
        pinSVG: BetssonStorePin,
        name: PRODUCT,
        description: "Betsson CASH"
      };
    case "betssoncashout":
      return {
        pinSVG: BetssonStorePin,
        name: PRODUCT,
        description: "Betsson CASH-out"
      };
    case "oktoexpress":
      return { pinSVG: OktoStorePin, name: PRODUCT, description: "OKTO.CASH" };
    case "oktoplus":
      return { pinSVG: OktoStorePin, name: PRODUCT, description: "OKTO.CASH" };
    case "cash":
    default:
      return { pinSVG: OktoStorePin, name: PRODUCT, description: "OKTO.CASH" };
  }
};

export const PRODUCT_DETAILS = getProductDetails();

export const isDesktop = window.innerWidth > 1024;

export const DAYS_MAP = Object.freeze({
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6
});
