import { useTranslation } from "react-i18next";
import direction_icon from "../icons/direction_icon.svg";
import { useGoogleAnalyticsContext } from "../services/GoogleAnalyticsContext";

type GetDirectionsProps = {
  directionsTo: { lat: number | undefined; lng: number | undefined };
};

const GetDirections = ({ directionsTo }: GetDirectionsProps) => {
  const { t } = useTranslation();
  const googlePushEvent = useGoogleAnalyticsContext();
  return (
    <a
      className={"get-directions gtm-track"}
      href={`https://www.google.com/maps/dir/?api=1&destination=${directionsTo.lat},${directionsTo.lng}`}
      target={"_blank"}
      rel="noreferrer"
      onClick={() => googlePushEvent("click_get_directions_map")}
    >
      <img src={direction_icon} alt={"get directions"} />
      <p>{t("getDirections")}</p>
    </a>
  );
};

export default GetDirections;
