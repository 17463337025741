import { createContext, useContext } from "react";
import i18n from "../locales/i18n";

type GoogleAnalyticsContextType = {
  country?: string | null;
  business_partner?: string;
};

export const GoogleAnalyticsContext = createContext<GoogleAnalyticsContextType>(
  {},
);

export const useGoogleAnalyticsContext = () => {
  const { country, business_partner } = useContext(GoogleAnalyticsContext);
  const googlePushEvent = (
    event: string,
    restEvents?: {
      [key: string]: string | undefined | null;
    },
  ) => {
    const pushData = {
      event,
      language: i18n.language,
      ...( country ? { country } : undefined ),
      ...( business_partner ? { business_partner } : undefined ),
      ...restEvents,
    }
    if (process.env.NODE_ENV === 'development') console.log('Pushing ', pushData)
    window.dataLayer.push(pushData);
  };
  return googlePushEvent;
};
