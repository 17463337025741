import axios from "axios";
import {
  BACK_END_BASE_URL,
  DAYS_MAP,
  MAX_MOBILE_SCREEN_WIDTH_PIXELS
} from "./../Constants";

export const isMobile = () =>
  window.innerWidth <= MAX_MOBILE_SCREEN_WIDTH_PIXELS;

export const ApiClient = axios.create({
  baseURL: `${BACK_END_BASE_URL}/api/store-locator`
});

export const copyText = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const reportIssueOnClick = () => {
  console.log("issue reported");
};

export const getSchedule = (schedule: string[] | undefined) => {
  return schedule?.map((item: string) => {
    const separator = item.indexOf(":");
    const day = item.substring(0, separator);
    const working_hours = item.substring(separator + 1, item.length);
    return { day, working_hours };
  });
};

export const addTimeSymbol = (time: number) => {
  let newTime;
  if (String(time).length < 4) {
    newTime = "0" + time;
    return newTime.slice(0, 2) + ":" + newTime.slice(2);
  }
  return String(time).slice(0, 2) + ":" + String(time).slice(2);
};

export const add1200 = (num: number) => num + 1200;

export const removeTimeSymbol = (time?: string) => time?.replace(":", "");

export function allIndexOf(str: string, toSearch: string) {
  const indices = [];
  for (
    let pos = str.indexOf(toSearch);
    pos !== -1;
    pos = str.indexOf(toSearch, pos + 1)
  ) {
    indices.push(pos);
  }
  return indices;
}

export const calculateDistanceBetween = (
  pointA: google.maps.LatLngLiteral,
  pointB: google.maps.LatLngLiteral
) => {
  const R = 6371e3; // Radius of the Earth in meters
  const dLat = ((pointB.lat - pointA.lat) * Math.PI) / 180; // Convert degrees to radians
  const dLon = ((pointB.lng - pointA.lng) * Math.PI) / 180; // Convert degrees to radians
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((pointA.lat * Math.PI) / 180) *
      Math.cos((pointB.lat * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in meters
  return distance;
};

export const createIframeTheme = (paletteName = "blue", theme = "light") => {
  const _paletteName = paletteName.toLowerCase();
  const lightOrDark = theme === "dark" ? 1000 : 0;
  return {
    "50": `${_paletteName}-${Math.abs(lightOrDark - 50)}`,
    "100": `${_paletteName}-${Math.abs(lightOrDark - 100)}`,
    "200": `${_paletteName}-${Math.abs(lightOrDark - 200)}`,
    "300": `${_paletteName}-${Math.abs(lightOrDark - 300)}`,
    "400": `${_paletteName}-${Math.abs(lightOrDark - 400)}`,
    "500": `${_paletteName}-${Math.abs(lightOrDark - 500)}`,
    "600": `${_paletteName}-${Math.abs(lightOrDark - 600)}`,
    "700": `${_paletteName}-${Math.abs(lightOrDark - 700)}`,
    "800": `${_paletteName}-${Math.abs(lightOrDark - 800)}`,
    "900": `${_paletteName}-${Math.abs(lightOrDark - 900)}`,
    "950": `${_paletteName}-${Math.abs(lightOrDark - 950)}`
  };
};

const parseTimeRange = (timeRange: string) => {
  if (timeRange.toLowerCase().includes("open 24 hours")) {
    return { openTime: 0, closeTime: 2359 };
  }

  const timeRegex =
    /\s*(\d{1,2})\s*:\s*(\d{2})\s*(AM|PM)?\s*\u2013\s*(\d{1,2})\s*:\s*(\d{2})\s*(AM|PM)?\s*/;
  const match = timeRange.match(timeRegex);

  if (!match) {
    throw new Error("Invalid time format");
  }

  const [, openH, openM, openPeriod, closeH, closeM, closePeriod] = match;

  let openHours = parseInt(openH, 10);
  const openMinutes = parseInt(openM, 10);
  let closeHours = parseInt(closeH, 10);
  const closeMinutes = parseInt(closeM, 10);

  if (openPeriod) {
    if (openPeriod === "PM" && openHours !== 12) openHours += 12;
    if (openPeriod === "AM" && openHours === 12) openHours = 0;
  }

  if (closePeriod) {
    if (closePeriod === "PM" && closeHours !== 12) closeHours += 12;
    if (closePeriod === "AM" && closeHours === 12) closeHours = 0;
  }

  return {
    openTime: openHours * 100 + openMinutes,
    closeTime: closeHours * 100 + closeMinutes
  };
};

export const isOpenNow = (openingHoursPeriods: string[] | undefined) => {
  if (!openingHoursPeriods || openingHoursPeriods.length === 0) return false;

  const now = new Date();
  const schedule = getSchedule(openingHoursPeriods)?.map((item) => {
    const period = parseTimeRange(item.working_hours);
    return {
      day: DAYS_MAP[item.day as keyof typeof DAYS_MAP],
      ...period
    };
  });

  const currentDay = now.getDay();
  const currentTime = now.getHours() * 100 + now.getMinutes();
  const currentDaySchedule = schedule?.find(
    (daySchedule) => daySchedule.day === currentDay
  );

  if (!currentDaySchedule) return false;

  return (
    currentDaySchedule.openTime <= currentTime &&
    currentTime <= currentDaySchedule.closeTime
  );
};
